const InlineEditor = require('@ckeditor/ckeditor5-build-inline/build/ckeditor')

let gEditor;

document.addEventListener("turbolinks:load", () => {
  if (document.querySelector( '#static_page_description_div' ) != null){
    InlineEditor
      .create( document.querySelector( '#static_page_description_div' ), {
          toolbar: [ 'fontColor', 'fontBackgroundColor', '|', 'bold', 'italic', 'underline', '|', 'bulletedList', 'numberedList', '|', 'undo', 'redo' ],
          placeholder: 'Info tekst'
      }  )
      .then( editor => {
          console.log( editor );
          console.log(Array.from( editor.ui.componentFactory.names()));
          gEditor = editor
      })
      .catch( error => {
          console.log( error );
      });
    if (document.querySelector( '#sp_form' ) != null){
      document.querySelector( '#sp_form' ).addEventListener( 'submit', () => {
        $('#static_page_description').val(gEditor.getData())
      } );
    }
  }
});
