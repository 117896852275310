const InlineEditor = require('@ckeditor/ckeditor5-build-inline/build/ckeditor')

let itEditor
let ftEditor

document.addEventListener("turbolinks:load", () => {

  if (document.querySelector( '#ile_info_text' ) != null){
    InlineEditor
      .create( document.querySelector( '#ile_info_text' ), {
          toolbar: [ 'fontColor', 'fontBackgroundColor', '|', 'bold', 'italic', 'underline', '|', 'bulletedList', 'numberedList', '|', 'undo', 'redo' ],
          placeholder: 'Info tekst'
      }  )
      .then( editor => {
        console.log( editor );
        console.log(Array.from( editor.ui.componentFactory.names()));
        itEditor = editor;
      })
      .catch( error => {
          console.log( error );
      });
    InlineEditor
      .create( document.querySelector( '#ile_footer_text' ), {
          toolbar: [ 'fontColor', 'fontBackgroundColor', '|', 'bold', 'italic', 'underline', '|', 'bulletedList', 'numberedList', '|', 'undo', 'redo' ],
          placeholder: 'Voettekst'
      } )
      .then( editor => {
        console.log( editor );
        console.log(Array.from( editor.ui.componentFactory.names()));
        itEditor = editor;
      })
      .catch( error => {
          console.log( error );
      });
    if (document.querySelector( '#et_form' ) != null){
      document.querySelector( '#et_form' ).addEventListener( 'submit', () => {
        $('#email_text_info_text').val(itEditor.getData())
        $('#email_text_footer_text').val(ftEditor.getData())
      } );
    }
  }
});
