// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels");
require("jquery");
require("popper.js");
require('bootstrap');

var $ = require('jquery');
global.$ = jQuery;

import "../stylesheets/application.scss";

require("blueimp-file-upload");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

require("datatables.net")
require('datatables.net-bs4')
require("datatables.net-bs4/css/dataTables.bootstrap4.min.css")

require('jquery-ui');
require('jquery-ui/ui/widgets/draggable')
require('jquery-ui/ui/widgets/droppable')
require('jquery-ui/ui/widgets/sortable')
require('jquery-ui/ui/disable-selection')

require("chartkick")

require("chart.js")

const dataTables = [];

import "./inline_editor"
import "./inline_editor_email_texts"
import "./inline_editor_static_pages"
import "./inline_editor_settings_infos"

document.addEventListener("turbolinks:load", () => {

  $.getScript("/js/mdb.min.js", function(){
     console.log('MDB Loaded.');
  });
  $.getScript("/js/bootstrap-material-datetimepicker-bs4.js", function(){
     console.log('BS 4 datetimepicker loaded');
  });
  if (dataTables.length === 0 && $('.data-table').length !== 0) {
    $('.data-table').each((_, element) => {
      dataTables.push($(element).DataTable({
        pageLength: 50
      }));
    });
  }

  scaleItems();

});

document.addEventListener("turbolinks:before-cache", () => {
  $.getScript("/js/mdb.min.js", function(){
     console.log('MDB Loaded before cache.');
  });
  while (dataTables.length !== 0) {
    dataTables.pop().destroy();
  }
});

$(window).bind('resize orientationchange', function() {
	scaleItems();
});

//$(document).ready(() => {
//	scaleItems();
//})

function scaleItems() {
  var window_height = $(window).height();

	if ($('main').length) {
		var content_height = window_height - 31; // window-height - footer-height
		var main_height = $('main').height();
    if ( $('body').hasClass('guest') ) {
      console.log(' lalalalalalalalala ');
      content_height = content_height - 90;
    }
    if ( main_height < content_height ) {
  		$('main').css('min-height', content_height+'px');
    }
	}

}
